// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-coins-js": () => import("./../src/pages/coins.js" /* webpackChunkName: "component---src-pages-coins-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invitations-js": () => import("./../src/pages/invitations.js" /* webpackChunkName: "component---src-pages-invitations-js" */),
  "component---src-pages-items-js": () => import("./../src/pages/items.js" /* webpackChunkName: "component---src-pages-items-js" */),
  "component---src-pages-membership-active-code-js": () => import("./../src/pages/membership/active-code.js" /* webpackChunkName: "component---src-pages-membership-active-code-js" */),
  "component---src-pages-membership-invite-js": () => import("./../src/pages/membership/invite.js" /* webpackChunkName: "component---src-pages-membership-invite-js" */),
  "component---src-pages-membership-open-vip-js": () => import("./../src/pages/membership/open-vip.js" /* webpackChunkName: "component---src-pages-membership-open-vip-js" */),
  "component---src-pages-membership-product-js": () => import("./../src/pages/membership/product.js" /* webpackChunkName: "component---src-pages-membership-product-js" */),
  "component---src-pages-membership-sell-active-code-js": () => import("./../src/pages/membership/sell-active-code.js" /* webpackChunkName: "component---src-pages-membership-sell-active-code-js" */),
  "component---src-pages-membership-sell-coin-js": () => import("./../src/pages/membership/sell-coin.js" /* webpackChunkName: "component---src-pages-membership-sell-coin-js" */),
  "component---src-pages-membership-vip-js": () => import("./../src/pages/membership/vip.js" /* webpackChunkName: "component---src-pages-membership-vip-js" */),
  "component---src-pages-orders-js": () => import("./../src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-platform-records-js": () => import("./../src/pages/platform-records.js" /* webpackChunkName: "component---src-pages-platform-records-js" */),
  "component---src-pages-reports-config-js": () => import("./../src/pages/reports/config.js" /* webpackChunkName: "component---src-pages-reports-config-js" */),
  "component---src-pages-reports-index-js": () => import("./../src/pages/reports/index.js" /* webpackChunkName: "component---src-pages-reports-index-js" */),
  "component---src-pages-reward-js": () => import("./../src/pages/reward.js" /* webpackChunkName: "component---src-pages-reward-js" */),
  "component---src-pages-themes-id-js": () => import("./../src/pages/themes/id.js" /* webpackChunkName: "component---src-pages-themes-id-js" */),
  "component---src-pages-themes-index-js": () => import("./../src/pages/themes/index.js" /* webpackChunkName: "component---src-pages-themes-index-js" */),
  "component---src-pages-tools-compress-picture-js": () => import("./../src/pages/tools/compress-picture.js" /* webpackChunkName: "component---src-pages-tools-compress-picture-js" */),
  "component---src-pages-tools-index-js": () => import("./../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

